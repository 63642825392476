<template>
  <img class="img" src="/static/img/about-banner.png"/>
  <!-- Creative -->
  <Creative/>
  <!-- Future -->
  <Future/>
  <!-- Team -->
  <Team/>
  <img class="img" src="/static/img/partners.jpg"/>
  <!-- Contact -->
  <Contact/>
</template>
<script lang="ts">
import Creative from '../components/about/Creative.vue'
import Future from '../components/about/Future.vue'
import Team from '../components/about/Team.vue'
import Contact from '../components/about/Contact.vue'
export default {
  components:{
    Creative,
    Future,
    Team,
    Contact
  }
}
</script>
<style lang="less" scoped>
.img{
  display: block;
  width: 100%;
}
</style>