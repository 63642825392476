
import Creative from '../components/about/Creative.vue'
import Future from '../components/about/Future.vue'
import Team from '../components/about/Team.vue'
import Contact from '../components/about/Contact.vue'
export default {
  components:{
    Creative,
    Future,
    Team,
    Contact
  }
}
