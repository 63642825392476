<template>
  <div class="container">
    <img class="back" src="../../assets/about/creative_back.jpg"/>
    <h3><span>盛世高创</span>  ·  CREATIVE </h3>
    <h4>引领企业数字化转型，助力数字中国发展</h4>
    <p>四川盛世高创科技有限公司是一家致力于以科技为产业赋能，以云计算、大数据、物联网及人工智能等技术赋能企业数字化创新，为企业数字化转型提供整体解决方案的高科技企业。盛世高创核心团队均来自国内外知名互联网企业，团队坚持以技术领先、技术优先为导向，以引领企业数字化创新为使命，已与各级政府及多家世界500强企业建立了深度合作关系。</p>
    <ul>
      <li>
        <img src="../../assets/about/about_img1.png"/>
      </li>
      <li>
        <img src="../../assets/about/about_img2.png"/>
      </li>
      <li>
        <img src="../../assets/about/about_img3.png"/>
      </li>
    </ul>
  </div>
</template>
<style lang="less" scoped>
.container{
  padding: 50px 25px 80px;
  color: #383838;
  position: relative;
  >h3{
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    >span{
      color: #F23D49;
    }
  }
  >h4{
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin-top: 15px;
  }
  >p{
    font-size: 16px;
    line-height: 2;
    font-weight: 300;
    margin-top: 25px;
    text-align: justify;
  }
  >ul{
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    >li{
      width: 22vw;
      margin: initial;
      >img{
        width: 100%;
        display: block;
      }
    }
  }
  .back{
    position: absolute;
    bottom: 0;
    display: block;
    left: 0;
    width: 100%;
    z-index: -1;
  }
}
</style>