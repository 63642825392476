<template>
  <div class="future-container">
    <img src="../../assets/about/logo.png"/>
    <p>这是一个巨匠造物的时代<br/>鼓舞着我们创造前所未有的未来</p>
    <h3>Create the future</h3>
    <div class="next-btn"></div>
  </div>
</template>
<style lang="less" scoped>
.future-container{
  width: 100%;
  position: relative;
  background: url(../../assets/about/about_back1.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  padding: 125px 0;
  >img{
    width: 14.53vw;
    display: block;
    margin: auto;
  }
  >p{
    line-height: 2.2;
    font-size: 15px;
    font-weight: 300;
    text-align: center;
    margin-top: 45px;
  }
  >h3{
    font-size: 28px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    margin-top: 30px;
  }
  .next-btn{
    width: 35px;
    height: 35px;
    margin: 100px auto auto;
    background-color: #F23D49;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &::after,&::before{
      content: '';
      width: 7px;
      height: 2px;
      background-color: #fff;
    }
    &::after{
      transform: rotate(-45deg) translateX(-2px);
    }
    &::before{
      transform: rotate(45deg) translateX(2px);
    }
  }
}
</style>