<template>
  <div class="team">
    <h3><span>团队</span>  ·  TEAM </h3>
    <p>盛世高创拥有众多参与过大型系统及数据平台规划设计的技术专家，丰富的系统架构及开发经验保证我们对每一套行业解决方案的规划、实施及运维都游刃有余。经过阿里云官方认证的专家顾问，为企业提供行之有效的产品购买及解决方案咨询服务。具备丰富的项目执行经验的项目经理，将为每一套解决方案的顺利实施与交付提供保障。</p>
    <ul>
      <li>
        <img src="../../assets/about/team1.png"/>
        创意及创新
      </li>
      <li>
        <img src="../../assets/about/team2.png"/>
        洞察及预见
      </li>
      <li>
        <img src="../../assets/about/team3.png"/>
        执行力及严谨
      </li>
    </ul>
  </div>
</template>
<style lang="less" scoped>
.team{
  background: url(../../assets/about/about_back2.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  padding: 60px 25px 110px;
  >h3{
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    >span{
      color: #F23D49;
    }
  }
  >p{
    font-size: 16px;
    line-height: 2;
    font-weight: 300;
    margin-top: 50px;
    text-align: justify;
  }
  >ul{
    margin-top: 70px;
    display: flex;
    >li{
      flex: 1;
      text-align: center;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      position: relative;
      >img{
        display: block;
        margin: auto auto 20px;
        width: 55px;
      }
      &::after{
        content: '';
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
        width: 6px;
        height: 1px;
        background-color: #fff;
      }
    }
  }
}
</style>