<template>
  <div class="contact">
    <h3><span>联系我们</span>  ·  CONTACT</h3>
    <ul>
      <li>
        <img src="../../assets/about/tel.png"/>
        <h3>合作电话</h3>
        <p style="font-size:18px"><a href="tel:028-6787-9897‬">028-6787-9897‬</a></p>
      </li>
      <li>
        <img src="../../assets/about/address.png"/>
        <h3>公司地址</h3>
        <p>四川省成都市天府大道1700号新世纪环球中心</p>
      </li>
      <li>
        <img src="../../assets/about/email.png"/>
        <h3>邮箱地址</h3>
        <p>service@ssgctech.com</p>
      </li>
    </ul>
  </div>
</template>
<style lang="less" scoped>
.contact{
  background: url(../../assets/about/about_back3.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  padding: 60px 25px 80px;
  >h3{
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    >span{
      color: #F23D49;
    }
  }
  >ul{
    margin-top: 45px;
    >li{
      text-align: center;
      color: #fff;
      &:not(:last-child){
        margin-bottom: 50px;
      }
      >img{
        width: 47.5px;
        height: 47.5px;
        display: block;
        margin: auto;
      }
      >h3{
        font-size: 16px;
        margin-top: 10px;
        font-weight: 600;
      }
      >p{
        font-size: 14px;
        font-weight: 300;
        margin-top: 10px;
        >a{
          color: #fff;
        }
      }
    }
  }
}
</style>